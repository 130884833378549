<script setup lang="ts">
import { type NotificationItem, Notifications } from '@kyvg/vue3-notification'
import { ThBanner } from '@/components/ui'
import type { ThBannerProps } from './ui/ThBanner.vue'

interface SlotProps {
  item: NotificationItem
  close: () => void
}

function getBannerColor(type: string = 'error') {
  return type as ThBannerProps['color']
}
</script>

<template>
  <Notifications width="100%" position="top center">
    <template #body="{ item, close }: SlotProps">
      <ThBanner
        :title="item.title"
        :text="item.text"
        is-dismissable
        @dismiss="close"
        :color="getBannerColor(item.type)"
      />
    </template>
  </Notifications>
</template>

<style>
.vue-notification-group {
  left: 0 !important;
}

.vue-notification-wrapper {
  @apply max-w-[1920px] !mx-auto;
}

.vue-notification-wrapper::before {
  @apply absolute inset-x-0 -top-px h-px;
  box-shadow:
    0px 0.30000001192092896px 0.8999999761581421px 0px rgba(0, 0, 0, 0.11),
    0px 1.600000023841858px 3.5999999046325684px 0px rgba(0, 0, 0, 0.13);
  content: '';
}
</style>
