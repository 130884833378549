import type { App } from 'vue'
import { createI18n } from './i18n'
import { createPinia } from './pinia'
import { createDayjs } from './dayjs'
import { createAmpli } from './ampli'
import { createSentry } from './sentry'
import { createCookiePolicy } from './cookiePolicy'

export async function createPlugins(app: App) {
  app.use(createSentry())
  app.use(createCookiePolicy())
  app.use(createI18n())
  app.use(createPinia())
  app.use(await createDayjs())
  app.use(createAmpli())
}
