import { createRouter, createWebHistory } from 'vue-router'

export const createAppRouter = () => {
  return createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/:organization',
        name: 'appointment_create',
        component: () => import('@/views/appointments/AppointmentCreateView.vue'),
        meta: {
          layout: 'blank'
        }
      },
      {
        path: '/:organization/appointments/:id',
        component: () => import('@/views/appointments/AppointmentEditView.vue'),
        beforeEnter(to) {
          // Ensure they always start at the root route
          if (to.name !== 'appointment') {
            return {
              name: 'appointment',
              params: to.params,
              query: to.query
            }
          }
        },
        children: [
          {
            name: 'appointment',
            path: '',
            component: () => import('@/views/appointments/AppointmentView.vue')
          },
          {
            name: 'appointment_cancelled',
            path: '/:organization/appointments/:id/cancelled',
            component: () => import('@/views/appointments/AppointmentCancelledView.vue')
          },
          {
            name: 'appointment_reschedule',
            path: '/:organization/appointments/:id/reschedule',
            component: () => import('@/views/appointments/AppointmentRescheduleView.vue')
          },
          {
            name: 'appointment_rescheduled',
            path: '/:organization/appointments/:id/rescheduled',
            component: () => import('@/views/appointments/AppointmentRescheduledView.vue')
          }
        ]
      },
      {
        // This is a 404 page where the merchant was found, but the page visited
        // did not exist
        path: '/:organization/:pathMatch(.*)*',
        name: 'not_found',
        component: () => import('@/views/NotFoundView.vue')
      },
      {
        // This is handled by App.vue, but its added here so that VueRouter
        // does not complain about not finding routes
        path: '/',
        component: () => import('@/views/NotFoundView.vue')
      }
    ]
  })
}

export default createAppRouter()
