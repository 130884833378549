import { Env } from '@/env'
import { createFetch } from '@vueuse/core'
import fetchRetry from 'fetch-retry'

const retryableFetch = fetchRetry(window.fetch, {
  retryOn(retries, error, response) {
    // 0 based
    if (retries > 2) {
      return false
    }

    // Network errors, the request was not sent
    if (!response || error) {
      return true
    }

    // timeout, too many requests
    if ([408, 429].includes(response.status)) {
      return true
    }

    if (response.status >= 500) {
      return true
    }

    return false
  },
  retryDelay: (attempt) => (attempt + 1) * 1000
})

/**
 * The backend requires the organization name to be appended to the end of the url
 * This composable configures the base url and automatically adds the organization name.
 * Also, if you add any query variables, they will be moved to the end of the url
 */
export const useThFetch = createFetch({
  baseUrl: Env.VITE_VUE_APP_API_BASE,
  options: {
    // @TODO use Env when vitest is implemented
    // We do not need retry in tests
    fetch: typeof global === 'object' ? undefined : retryableFetch
  }
})
