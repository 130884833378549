<script lang="ts" setup>
import { ThIcon } from '@/components/ui'
import { computed } from 'vue'

export interface ThBannerProps {
  color: 'info' | 'error' | 'success' | 'warning'
  isDismissable?: boolean
  title?: string
  text?: string
}

const props = defineProps<ThBannerProps>()

defineEmits<{
  dismiss: [payload: void]
}>()

const icon = computed(() => {
  const { color } = props

  if (color === 'error') {
    return 'Warning'
  }

  return 'WarningTriangle'
})
</script>

<template>
  <div
    class="px-4 py-3.5 text-sm text-dark flex border-b border-gray-400 items-center"
    role="alert"
    :class="{
      'bg-pink-25': color === 'warning',
      'bg-red-25': color === 'error'
    }"
  >
    <div class="flex items-center mr-auto">
      <span
        class="mr-3.5"
        :class="{
          'text-warning': color === 'warning',
          'text-error': color === 'error'
        }"
      >
        <ThIcon class="w-4 leading-[0]" :is="icon" />
      </span>

      <div>
        <h4 class="font-semibold inline-block">
          <slot name="title">{{ title }}</slot>
        </h4>

        <p class="inline-block">
          <slot name="text">{{ text }}</slot>
        </p>
      </div>
    </div>

    <button
      class="flex items-center justify-center w-7 h-7 rounded ml-2 text-dark"
      @click="$emit('dismiss')"
      v-if="isDismissable"
      type="button"
    >
      <ThIcon class="w-3" is="Xmark" />
    </button>
  </div>
</template>
