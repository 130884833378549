import type { Plugin } from 'vue'

declare global {
  interface Window {
    CookieScript?: {
      instance?: {
        currentState(): {
          categories: string[]
        }
      }
    }
  }
}

/**
 * This plugin injects the script for the cookie policy modal.
 * https://cookie-script.com/
 */
export function createCookiePolicy(): Plugin {
  return {
    install() {
      const url = '//cdn.cookie-script.com/s/a895bc34a48888cee5d82a82ca1c48fe.js'
      const scriptNode = document.createElement('script')
      scriptNode.setAttribute('src', url)
      document.body.appendChild(scriptNode)
    }
  }
}
