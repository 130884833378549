<script lang="ts" setup>
import { useConfigurationStore } from '@/stores/useConfigurationStore'
import { computed } from 'vue';

const store = useConfigurationStore()
const logo = computed(() => store.reservations.customization.theme.logo)
</script>

<template>
  <img v-if="logo" :src="logo" alt="Logo" />
</template>
