import './assets/main.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPlugins } from './plugins'

async function initialize() {
  const app = createApp(App)

  await createPlugins(app)

  app.use(router)

  app.mount('#app')
}

initialize()
