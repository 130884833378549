<script lang="ts" setup>
import { useI18n } from '@/plugins/i18n'
import SalonLogo from './SalonLogo.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useConfigurationStore } from '@/stores/useConfigurationStore'
import { ThContainer, ThIcon } from '@/components/ui'
import defaultHeaderBackgroundImage from '@/assets/images/default-header-background.jpg'

export interface AppHeaderProps {
  type?: 'compact' | 'extended'
}

withDefaults(defineProps<AppHeaderProps>(), {
  type: 'extended'
})

const { t } = useI18n()
const route = useRoute()
const store = useConfigurationStore()
const logo = computed(() => store.reservations.customization.theme.logo)
const theme = store.reservations.customization.theme

/**
 * If adding more pages, it would be better to declare titles in route.meta,
 * but we have just two routes now and no plans to add new ones.
 */
const title = computed(() => {
  const { name } = route

  if (name === 'appointment_create') {
    return t('pages.appointments.titles.create')
  }

  if (name === 'appointment_edit') {
    return t('pages.appointments.titles.edit')
  }

  return null
})

const backgroundImage = computed(() => theme.header_background_image)
</script>

<template>
  <header v-if="type === 'extended'" class="relative flex items-center h-[8.75rem] sm:h-[10rem]">
    <div class="absolute inset-0 pointer-events-none -z-[1]">
      <img
        v-if="backgroundImage"
        class="w-full h-full object-cover"
        :src="backgroundImage['1x']"
        :srcset="`
          ${backgroundImage['1x']} 1x,
          ${backgroundImage['2x']} 2x,
          ${backgroundImage['3x']} 3x,
        `"
      />

      <img v-else :src="defaultHeaderBackgroundImage" class="w-full h-full object-cover" />
    </div>

    <ThContainer class="flex justify-between items-start max-sm:pr-0">
      <RouterLink
        v-if="logo"
        class="flex items-center justify-center w-[6.75rem] h-[6.75rem] sm:w-32 sm:h-32 p-2 rounded-md shadow-black/10 shadow-[0_0_3px] bg-white flex-shrink-0"
        :to="{
          name: 'appointment_create',
          params: {
            organization: store.organization
          }
        }"
      >
        <SalonLogo class="h-auto w-auto max-w-full max-h-full" />
      </RouterLink>

      <div
        v-if="theme.instagram_url || theme.facebook_url"
        class="flex items-center bg-[#141414] bg-opacity-50 rounded max-sm:rounded-r-none p-2 space-x-3 text-white text-xs"
      >
        <a
          v-if="theme.instagram_url"
          class="flex items-center"
          :href="theme.instagram_url"
          target="_blank"
        >
          <ThIcon is="Instagram" class="w-4 mr-1" />

          {{ t('common.socials.instagram') }}
        </a>

        <a
          v-if="theme.facebook_url"
          class="flex items-center"
          :href="theme.facebook_url"
          target="_blank"
        >
          <ThIcon is="Facebook" class="w-2.5 mr-2" />

          {{ t('common.socials.facebook') }}
        </a>
      </div>
    </ThContainer>
  </header>

  <header
    v-else
    class="max-md:sticky top-0 inset-x-0 z-10 h-header-height shadow-header flex items-center justify-between md:justify-start px-4 bg-white shadow"
  >
    <RouterLink
      v-if="logo"
      :to="{
        name: 'appointment_create',
        params: {
          organization: store.organization
        }
      }"
      class="md:mr-8 h-full max-h-[1.5rem] max-w-[9.5rem]"
    >
      <SalonLogo class="h-auto w-auto max-w-full max-h-full" />
    </RouterLink>

    <h6
      v-if="title"
      class="uppercase md:normal-case md:font-bold text-tiny md:text-sm text-gray-800"
    >
      {{ title }}
    </h6>
  </header>
</template>
