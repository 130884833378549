import { useConfigurationStore } from '@/stores/useConfigurationStore'
import { ampli, type Environment } from '../ampli'
import type { Plugin } from 'vue'
import { Env } from '@/env'

type Ampli = typeof ampli
type NonEventMethods = 'load' | 'track' | 'flush' | 'identify'

type AmpliMethodNames = {
  [K in keyof Ampli]: Ampli[K] extends (...args: any[]) => any ? K : never
}[keyof Ampli]

type AmpliEvents = Exclude<AmpliMethodNames, NonEventMethods>

interface BaseEventProperties {
  organization: string
}

type ExcludeBaseEventProps<T> = T extends BaseEventProperties
  ? Omit<T, keyof BaseEventProperties>
  : T

/**
 * Returns a type safe `track` proxy for usage in application code
 */
export function useAmpli() {
  const store = useConfigurationStore()

  return {
    track<T extends AmpliEvents, U extends Parameters<Ampli[T]>>(
      event: T,
      ...args: [ExcludeBaseEventProps<U[0]>?, U[1]?]
    ) {
      const baseProperties: BaseEventProperties = {
        organization: store.organization
      }

      const eventProperties = {
        ...baseProperties,
        ...args[0]
      }

      const eventOptions = args[1]

      if (!isEnabled) {
        // If the plugin is not enabled, log it for debugging
        return console.info(`[Ampli Event: ${event}]`, {
          properties: eventProperties,
          options: eventOptions
        })
      }

      return ampli[event].apply(ampli, [eventProperties, eventOptions] as any) // eslint-disable-line
    }
  }
}

let isEnabled = false

function userAgreedToTracking() {
  if (!window.CookieScript?.instance) {
    return false
  }

  const state = window.CookieScript.instance.currentState()

  return state.categories?.includes('targeting')
}

async function loadAmpliIfAllowed() {
  if (ampli.isLoaded) {
    return
  }

  if (!userAgreedToTracking()) {
    return
  }

  isEnabled = true

  console.info('[ampli]: Plugin loaded!')

  let environment: Environment = 'default'

  if (Env.PROD) {
    environment = window.location.origin.includes('staging-online-booking.tillhub.com')
      ? 'staging'
      : 'production'
  }

  await ampli.load({
    environment,
    client: {
      configuration: {
        defaultTracking: true,
        trackingOptions: {
          // Copied from dashboard, its important
          ipAddress: false
        }
      }
    }
  })
}

/**
 * Creates the amplitude plugin. We listen for the Cookie Policy events
 * and if the user has agreed to be tracked, we enable the plugin.
 */
export function createAmpli(): Plugin {
  return {
    install() {
      if (window.CookieScript?.instance) {
        loadAmpliIfAllowed()
      }

      const events = ['CookieScriptLoaded', 'CookieScriptAccept', 'CookieScriptAcceptAll']

      events.forEach((event) => {
        window.addEventListener(event, async () => {
          await loadAmpliIfAllowed()
        })
      })
    }
  }
}
