import { defineStore } from 'pinia'
import type { Configuration } from '@/types'

export interface ConfigurationStoreState extends Configuration {
  organization: string
}

export const useConfigurationStore = defineStore('configuration', {
  state(): ConfigurationStoreState {
    return {
      organization: '',
      client_account: {
        name: ''
      },
      reservations: {
        customization: {
          reservations_page_activated: false,
          theme: {
            logo: '',
            color: '#1B6AD7'
          },
          terms_of_service_url: null,
          max_services_per_appointment: 3,
          allow_employee_selection: true,
          reservation_frequency_minutes: 10,
          max_booking_lead_time: {
            value: 3,
            type: 'month'
          },
          min_booking_lead_time: {
            value: 1,
            type: 'day'
          },
          cancellation_lead_time: {
            value: 1,
            type: 'day'
          }
        }
      },
      general: {
        default_currency: '',
        default_date_format: '',
        default_timezone: 'utc'
      }
    }
  },
  getters: {
    termsOfServiceURL(state) {
      return state.reservations.customization.terms_of_service_url
    }
  },
  actions: {
    setOrganization(organization: string) {
      this.organization = organization
    },
    setState(state: Omit<ConfigurationStoreState, 'organization'>) {
      // Temporary fix until backend normalizes the data
      Object.assign(this.general, state.general)

      this.reservations = state.reservations
      this.client_account = state.client_account
    }
  }
})

export type ConfigurationStore = ReturnType<typeof useConfigurationStore>
