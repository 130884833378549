<script lang="ts" setup>
import TillhubLogo from '@/assets/images/TillhubLogo.svg'
import { IMPRINT_URL, PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '@/common/constants'
import { ThContainer } from '@/components/ui'
import { useI18n } from '@/plugins/i18n'

const { t } = useI18n()

const links = [
  {
    label: t('nav.footer.items.terms_and_conditions'),
    url: TERMS_AND_CONDITIONS_URL,
    testId: 'terms-and-conditions-link'
  },
  {
    label: t('nav.footer.items.privacy_policy'),
    url: PRIVACY_POLICY_URL,
    testId: 'privacy-policy-link'
  },
  { label: t('nav.footer.items.imprint'), url: IMPRINT_URL, testId: 'imprint-link' }
]
</script>

<template>
  <th-container>
    <footer
      class="md:mt-4 pt-6 pb-4 md:py-6 md:border-t border-gray-400 text-center flex flex-col md:flex-row items-center md:justify-between text-xs"
    >
      <nav class="inline-block mb-2 md:mb-0">
        <a
          class="inline-block text-gray-800 hover:underline mx-2 mb-1"
          v-for="link in links"
          :href="link.url"
          :key="link.label"
          target="_blank"
          :data-testid="link.testId"
        >
          {{ link.label }}
        </a>
      </nav>

      <div class="flex items-center text-gray-800">
        <p class="mr-1">
          {{ t('common.powered_by') }}
        </p>

        <a href="https://www.tillhub.de/" target="_blank" data-testid="powered-by-tillhub-link">
          <TillhubLogo />
        </a>
      </div>
    </footer>
  </th-container>
</template>
