import { Env } from '@/env'
import router from '@/router'
import * as Sentry from '@sentry/vue'
import type { App } from 'vue'

export function useSentry() {
  return Sentry
}

/**
 * These errors tend to occur when a new release is deployed and the client has cached files locally
 * which have references to files that no longer exist.
 * Since these errors do not require fixing, we do not care to report them.
 */
const ignoreErrorPatterns = [
  'Failed to fetch dynamically imported module',
  'Unable to preload CSS for',
  'Importing a module script failed.'
]

function isErrorIgnored(error: unknown) {
  if (error instanceof Error) {
    return ignoreErrorPatterns.some(
      (pattern) => error.message?.toLowerCase().includes(pattern.toLowerCase())
    )
  }

  return false
}

export function createSentry() {
  return {
    install(app: App) {
      Sentry.init({
        app,
        enabled: Env.PROD,
        dsn: Env.VITE_SENTRY_DSN,
        environment: Env.MODE,
        beforeSend(event, hint) {
          if (isErrorIgnored(hint.originalException)) {
            return null
          }

          return event
        },
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router)
          })
        ]
      })
    }
  }
}
