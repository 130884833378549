<script setup lang="ts">
import { computed } from 'vue'
import ArrowLong from '@/assets/icons/ArrowLong.svg'
import Check from '@/assets/icons/Check.svg'
import ChevronDown from '@/assets/icons/ChevronDown.svg'
import Search from '@/assets/icons/Search.svg'
import Spinner from '@/assets/icons/Spinner.svg'
import Warning from '@/assets/icons/Warning.svg'
import WarningTriangle from '@/assets/icons/WarningTriangle.svg'
import Xmark from '@/assets/icons/Xmark.svg'
import ChevronRight from '@/assets/icons/ChevronRight.svg'
import ChevronLeft from '@/assets/icons/ChevronLeft.svg'
import Calendar from '@/assets/icons/Calendar.svg'
import CircleCheck from '@/assets/icons/CircleCheck.svg'
import Pin from '@/assets/icons/Pin.svg'
import TaskList from '@/assets/icons/TaskList.svg'
import CalendarRemove from '@/assets/icons/CalendarRemove.svg'
import Instagram from '@/assets/icons/Instagram.svg'
import Facebook from '@/assets/icons/Facebook.svg'

const props = defineProps<{
  is: keyof typeof IconMap
}>()

/**
 * @docs When adding new icons, remove the width/height attributes from the
 * svg file
 */
const IconMap = {
  ArrowLong,
  Check,
  ChevronDown,
  Search,
  Spinner,
  Warning,
  WarningTriangle,
  Xmark,
  ChevronLeft,
  ChevronRight,
  Calendar,
  CircleCheck,
  Pin,
  TaskList,
  CalendarRemove,
  Instagram,
  Facebook
}

const component = computed(() => {
  return IconMap[props.is]
})
</script>

<template>
  <component class="th-icon" v-if="component" :is="component" />
</template>
