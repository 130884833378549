<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router'
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'
import { useConfigurationStore } from '@/stores/useConfigurationStore'
import { useConfigurationQuery } from '@/api/useConfigurationQuery'
import { computed, onErrorCaptured, ref, watch } from 'vue'
import { ThSpinner } from '@/components/ui'
import { hexToRGB } from '@/utils/hexToRGB'
import { useTitle } from '@vueuse/core'
import { useI18n } from '@/plugins/i18n'
import UnexpectedErrorView from './views/UnexpectedErrorView.vue'
import NotFoundView from './views/NotFoundView.vue'
import NotificationsContainer from './components/NotificationsContainer.vue'
import { Env } from './env'

const route = useRoute()

const { t } = useI18n()

useTitle(t('general.title'))

const store = useConfigurationStore()

const layout = computed(() => route.meta?.layout || 'default')

watch(route, async (route) => {
  if (store.organization) {
    return
  }

  const organization = route.params.organization

  if (!organization) {
    showMerchantNotFoundScreen.value = true
    return
  }

  store.setOrganization(organization as string)

  await fetchConfiguration()
})

const {
  data,
  isFetching,
  statusCode,
  execute: fetchConfiguration
} = useConfigurationQuery({
  immediate: false
})

watch(statusCode, (statusCode) => {
  if (statusCode === null) {
    return
  }

  if (statusCode === 200) {
    return
  }

  if (statusCode === 404) {
    showMerchantNotFoundScreen.value = true
    return
  }

  showUnexpectedErrorScreen.value = true
})

watch(
  data,
  (data) => {
    if (!data) {
      return
    }

    const isReservationsPageActivated =
      data.configuration.reservations.customization.reservations_page_activated

    if (!isReservationsPageActivated) {
      showMerchantNotFoundScreen.value = true
      return
    }

    store.setState(data.configuration)

    /**
     * Merchant can specify primary color from Dashboard settings,
     * so create a style tag and inject it in document.
     */
    const styleTag = document.createElement('style')

    const { color } = data.configuration.reservations.customization.theme

    // We need to convert it to RGB following the rules from the docs so that
    // opacity modifiers work properly ( eg text-primary/50 order border-primary/10 )
    // https://tailwindcss.com/docs/customizing-colors#using-css-variables
    const rgb = hexToRGB(color) ?? (hexToRGB('#1B6AD7') as number[])

    styleTag.innerHTML = `:root { --th-primary: ${rgb.join(', ')}; }`

    document.head.appendChild(styleTag)
  },
  {
    immediate: true
  }
)

if (Env.PROD) {
  /**
   * In production, if an uncaught exception has been propagated to this component
   * we will show the global error screen. In dev environments, we don't want to do that
   * as it will cause the app to lose state by removing the old component, which makes
   * debugging harder.
   */
  onErrorCaptured(() => {
    showUnexpectedErrorScreen.value = true
  })
}

const showMerchantNotFoundScreen = ref(false)
const showUnexpectedErrorScreen = ref(false)
</script>

<template>
  <UnexpectedErrorView v-if="showUnexpectedErrorScreen" />

  <NotFoundView v-else-if="showMerchantNotFoundScreen" class="min-h-screen" />

  <div v-else class="flex flex-col md:min-h-screen max-w-[1920px] mx-auto">
    <div v-if="isFetching || !store.organization" class="h-screen flex items-center justify-center">
      <ThSpinner />
    </div>

    <template v-else-if="layout === 'blank'">
      <RouterView />
    </template>

    <template v-else>
      <AppHeader />

      <div class="flex flex-col md:flex-1">
        <RouterView />
      </div>
    </template>

    <NotificationsContainer />

    <AppFooter />
  </div>
</template>
