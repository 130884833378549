// Using `beta` version because of this issue
// https://github.com/intlify/vue-i18n-next/issues/1327
import * as i18n from 'vue-i18n'
import en from '../locales/en.json'
import de from '../locales/de.json'
import { Env } from '@/env'

const messages = {
  en,
  // We need to type cast non-default languages, because they may have missing keys
  de: de as MessageSchema
} as const

type MessageSchema = typeof en

type Locale = 'en' | 'de'

type NumberSchema = {
  currency: {
    style: 'currency'
    currencyDisplay: 'symbol'
    currency: string
  }
}

let targetLocale: string | undefined

if (Env.DEV) {
  targetLocale = 'en'
} else {
  targetLocale = 'de'
}

/**
 * Currently, the locale is hardcoded to german.
 * Uncomment code below when making it multilingual
 */
// const supportedLocales = Object.keys(messages)

// const initialLocale = window.navigator.language
// if (supportedLocales.includes(initialLocale)) {
//   targetLocale = initialLocale
// } else {
//   // if en-us, try matching just by `en`
//   const [baseLocale] = initialLocale.split('-')

//   targetLocale = supportedLocales.find((l) => l.startsWith(baseLocale))
// }

// Injects a type safe i18n plugin
// @TODO see if we actually want this one
export function useI18n(
  options?: i18n.UseI18nOptions<{ message: MessageSchema; number: NumberSchema }, Locale>
) {
  return i18n.useI18n<{ message: MessageSchema; number: NumberSchema }, Locale>({
    ...options
  })
}

export function createI18n() {
  const fallbackLocale = 'en'

  return i18n.createI18n<[MessageSchema], Locale>({
    locale: targetLocale || fallbackLocale,
    legacy: false,
    globalInjection: true,
    numberFormats: {
      en: {
        currency: {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'symbol'
        }
      },
      de: {
        currency: {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol'
        }
      }
    },
    fallbackLocale: {
      default: [fallbackLocale]
    },
    missingWarn: false,
    fallbackWarn: false,
    messages
  })
}
