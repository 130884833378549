<script lang="ts" setup>
import { useI18n } from '@/plugins/i18n'

const { t } = useI18n()
</script>

<template>
  <div class="h-screen flex flex-col items-center justify-center text-gray-800">
    <h1 class="mb-4 text-[2.125rem] leading-10 font-bold">
      {{ t('common.errors.unexpected_error.title') }}
    </h1>

    <p class="text-base">
      {{ t('common.errors.unexpected_error.description') }}
    </p>
  </div>
</template>
