import type { Configuration } from '@/types/Configuration'
import { useThFetch } from '@/composables/useThFetch'
import { useConfigurationStore } from '@/stores/useConfigurationStore'
import type { UseFetchOptions } from '@vueuse/core'
import type { Branch } from '@/types'

export function useConfigurationQuery(options?: UseFetchOptions) {
  const store = useConfigurationStore()

  return useThFetch(() => `/v0/configurations/public/booking/${store.organization}`, {
    afterFetch(ctx) {
      return {
        ...ctx,
        data: ctx.data.results
      }
    },
    ...options
  }).json<{
    configuration: Configuration
    branch: Branch
  }>()
}
